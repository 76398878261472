









import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import EmbedImage from './components/EmbedImage.vue';
import plugins from './plugins';
import toolbar from './toolbar';

@Component({
    components: {
      EmbedImage,
    },
  },
)

export default class Tinymce extends Vue {

  @Prop({default: () => 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')}) id!: string;
  @Prop({default: ''}) value!: string;
  @Prop({required: false, default: () => []}) toolbar!: object[];
  @Prop({default: false}) menubar!: any;
  @Prop({default: false}) embeddableImage!: boolean;
  @Prop({required: false, default: 360}) height!: number;

  hasChange = false;
  hasInit = false;
  tinymceId = this.id;

  @Watch('value')
  valueChange(val: any) {
    if (!this.hasChange && this.hasInit) {
      this.$nextTick(() => (window as any).tinymce.get(this.tinymceId).setContent(val || ''));
    }
  }

  initTinymce() {
    (window as any).tinymce.init({
      selector: `#${this.tinymceId}`,
      height: this.height,
      body_class: 'panel-body ',
      object_resizing: false,
      toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
      menubar: this.menubar,
      plugins,
      end_container_on_empty_block: true,
      powerpaste_word_import: 'clean',
      paste_use_dialog: false,
      paste_auto_cleanup_on_paste: true,
      paste_convert_headers_to_strong: false,
      paste_strip_class_attributes: 'all',
      paste_remove_spans: true,
      paste_remove_styles: true,
      paste_retain_style_properties: '',
      autoresize_bottom_margin: '10px',
      code_dialog_height: 450,
      code_dialog_width: 1000,
      advlist_bullet_styles: 'square',
      advlist_number_styles: 'default',
      imagetools_toolbar: '',
      default_link_target: '_blank',
      link_title: false,
      init_instance_callback: (editor: any) => {
        if (this.value) {
          editor.setContent(this.value);
        }
        this.hasInit = true;
        editor.on('NodeChange Change KeyUp SetContent', () => {
          this.hasChange = true;
          this.$emit('input', editor.getContent());
        });
      },
    });
  }

  destroyTinymce() {
    if ((window as any).tinymce.get(this.tinymceId)) {
      (window as any).tinymce.get(this.tinymceId).destroy();
    }
  }

  setContent(value: any) {
    (window as any).tinymce.get(this.tinymceId).setContent(value);
  }

  getContent() {
    (window as any).tinymce.get(this.tinymceId).getContent();
  }

  imageSuccess(base64: string) {
    (window as any).tinymce.get(this.tinymceId).insertContent(`<img src="${base64}" />`);
  }

  mounted() {
    this.initTinymce();
  }

  activated() {
    this.initTinymce();
  }

  deactivated() {
    this.destroyTinymce();
  }

  destroyed() {
    this.destroyTinymce();
  }

}
