
























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ImageEmbed extends Vue {

  @Prop({default: '#1890ff'}) color!: string;

  dialogVisible = false;
  value: string = '';
  imageUrl: string = '';
  multiple: boolean = false;
  allowInsert: boolean = false;

  handleSubmit() {
    this.$emit('insertImage', this.imageUrl);
    this.imageUrl = '';
    this.allowInsert = false;
    this.dialogVisible = false;
  }

  handleCancel() {
    this.imageUrl = '';
    this.allowInsert = false;
    this.dialogVisible = false;
  }

  handleSuccess(res: any, file: any) {
    this.allowInsert = true;
    this.imageUrl = res.base64data;
  }

  beforeUpload(file: any) {
    this.imageUrl = '';

    const allowedTypes: string[] = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
    ];

    const isImage = allowedTypes.includes(file.type);
    const isLessMaxSize = file.size / 1024 < 50; // max size 50KB

    if (!isImage) {
      this.$message.error('Image upload must be a valid image');
    }

    if (!isLessMaxSize) {
      this.$message.error('Image upload size can not exceed 50KB');
    }
    return isImage && isLessMaxSize;
  }

  encodeImage(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        const base64data = reader.result;
        setTimeout(() => resolve({base64data}), 200);
      };
    });
  }
}
